import React, { memo } from 'react'
import { navigate } from 'gatsby'
import { RouteComponentProps } from '@reach/router'
import { Button, MobileGolfClubCard } from '@elements'
import { AccountLayout } from '@layouts'
import { useWindowSize, useBrowsingView } from '@hooks'
import { List, GolfClubModelCard } from '@components'
import './browsing-history.scss'
import { ProductPageItemType } from '@types'

interface AccountBrowsingHistory extends RouteComponentProps { }

const AccountBrowsingHistory: React.FC<AccountBrowsingHistory> = () => {
  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])
  const { deleteBrowsingView, clearBrowsingView, browsingViewData, loading } = useBrowsingView()

  if (!loading && !browsingViewData?.res) navigate('/home')
  if (!browsingViewData) return null

  const sortedUserBrowsingHistory = [...browsingViewData?.res?.entries].sort((a, b) => {
    const aDate = +new Date(a.updatedAt)
    const bDate = +new Date(b.updatedAt)

    return bDate - aDate
  })

  return (
    <AccountLayout pageClassName={'account-browsing-history'} hideLayout={isMobileScreen}>
      <div className={'account-layout-page-title'}>
        <h6>MY BROWSING HISTORY</h6>
        <Button type={'roundWithHoverBg'} onClick={() => clearBrowsingView()}>
          Remove all
        </Button>
      </div>
      {isMobileScreen ? (
        <div className={'account-browsing-history-mobile-content'}>
          {sortedUserBrowsingHistory.map(browsingView => {
            const { golfClubModel, golfEquipmentModel } = browsingView
            const isGolfClub = golfClubModel.type === 'GOLF_CLUB_MODEL'
            const slug = golfClubModel.slug || golfEquipmentModel.slug
            const avatar = browsingView?.golfClubModel?.avatar || browsingView?.golfEquipmentModel?.avatar || ''
            const title = browsingView?.golfClubModel?.title || browsingView?.golfEquipmentModel?.title
            const subtitle = browsingView.golfClubModel?.brand?.title || browsingView?.golfEquipmentModel?.brand?.title
            const itemType: ProductPageItemType = isGolfClub ? 'GolfClubModel' : 'GolfEquipmentModel'
            return (
              <MobileGolfClubCard
                onClick={() => navigate(`/product/${slug}/${itemType}`)}
                key={browsingView.golfClubModel?.id || browsingView.golfEquipmentModel?.id}
                avatar={avatar}
                title={title}
                subtitle={subtitle}
                onDelete={() => deleteBrowsingView(browsingView.id)}
              />
            )
          })}
        </div>
      ) : (
        <List
          list={sortedUserBrowsingHistory}
          breakpointColumns={{
            mobile: 1,
            landscape: 2,
            tablet: 2,
            desktop: 2
          }}
          Component={({ listData }) => (
            <GolfClubModelCard
              onDelete={() => deleteBrowsingView(listData.id)}
              golfModel={listData.golfClubModel || listData.golfEquipmentModel}
            />
          )}
          noItemMessage={'Your history is empty...'}
        />
      )}
    </AccountLayout>
  )
}

export default memo(AccountBrowsingHistory)
